<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 md12>
      <v-card v-if="detail">
        <titleCard
          title="Estado del producto en Google"
          subtitle="Los cambios pueden tardar minutos en quedar reflejados aca"
        >
        </titleCard>
        <v-divider></v-divider>
        <v-card-text v-if="google.id === undefined">
          Producto aun no ha sido rastreado por google
        </v-card-text>
        <v-card-text v-else>
          <template v-for="(g, key) in google">
            <p v-if="g !== null" :key="key">
              <template v-if="typeof g === 'string' || typeof g === 'number'">
                <v-text-field v-model="google[key]" hide-details :label="key"></v-text-field>
              </template>
              <template v-else-if="typeof g === 'object' && key === 'additionalImageLinks'">
                {{ key }}:
                <template v-for="link in g">
                  <p :key="link" class="mb-0">
                    <a :href="link">{{ link }}</a>
                  </p>
                </template>
              </template>
              <template v-else-if="typeof g === 'object' && key === 'price'">
                <template v-for="(link, k) in g">
                  <p v-if="k === 'value'" :key="link" class="mb-0">
                    <v-text-field v-model="google[key][k]" hide-details :label="key"></v-text-field>
                  </p>
                </template>
              </template>
              <template v-else>
                {{ key }}: {{ g }}
              </template>
            </p>
          </template>
        </v-card-text>
        <v-card-actions v-if="google.id !== undefined">
          <v-btn color="error" flat @click="handleDelete"> ELIMINAR </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="save"> Actualizar </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { GET_PRODUCT_GOOGLE_FIXED, GET_PRODUCT_GOOGLE } from '../../config'
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'ProductGoogle',
  components: { titleCard },
  props: ['product', 'getProduct'],
  data() {
    return {
      loading: false,
      google: {},
      detail: null
    }
  },
  watch: {
    product() {
      this.initComponent()
    }
  },
  mounted() {
    this.initComponent()
  },
  methods: {
    async getGoogle() {
      if (this.detail.id !== undefined) {
        try {
          const id = this.detail.id
          const res = await this.$http.get(`${GET_PRODUCT_GOOGLE}/${id}`)
          this.google = res.data
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    async fixed() {
      this.loading = true
      try {
        const id = this.detail.id
        await this.$http.get(`${GET_PRODUCT_GOOGLE_FIXED}/${id}`)
        this.getGoogle()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async save() {
      this.loading = true
      try {
        const id = this.detail.id
        const google = this.google
        const res = await this.$http.put(`${GET_PRODUCT_GOOGLE}/${id}`, {
          google
        })
        this.google = res.data
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Los cambios pueden tardar minutos en quedar reflejados aca'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async delete() {
      this.loading = true
      try {
        const id = this.detail.id
        await this.$http.delete(`${GET_PRODUCT_GOOGLE}/${id}`)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    handleDelete() {
      const resultado = confirm('ESTA SEGURO DE ELIMINAR ESTE REGISTRO?')
      if (resultado) {
        this.delete()
      }
    },
    initComponent() {
      this.detail = this.product
      this.getGoogle()
    }
  }
}
</script>
